import { FILE_STATES, logger } from "@rpldy/shared";
import createState from "@rpldy/simple-state";
import { UPLOADER_EVENTS } from "@rpldy/uploader";
import { RETRY_EXT, RETRY_EVENT } from "./consts";
const FAILED_STATES = [FILE_STATES.ABORTED, FILE_STATES.ERROR];

const removeItemFromState = (retryState, id) => {
  retryState.updateState(state => {
    const batchId = state.failed[id].batchId;
    delete state.failed[id];
    const biMapIndex = state.batchIdsMap[batchId].indexOf(id);
    state.batchIdsMap[batchId].splice(biMapIndex, 1);

    if (!state.batchIdsMap[batchId].length) {
      delete state.batchIdsMap[batchId];
    }
  });
};

const uploadFailedIds = (uploader, retryState, trigger, ids, options) => {
  const failed = retryState.getState().failed;
  ids = ids || Object.keys(failed);
  const uploads = ids.map(id => failed[id]).filter(Boolean);

  if (uploads.length) {
    var _options;

    options = { ...(options || null),
      autoUpload: typeof ((_options = options) === null || _options === void 0 ? void 0 : _options.autoUpload) !== "undefined" ? options.autoUpload : true
    };
    trigger(RETRY_EVENT, {
      items: uploads,
      options
    });
    ids.forEach(id => removeItemFromState(retryState, id));
    uploader.add(uploads, options);
  }

  return !!uploads.length;
};

const retryItem = (uploader, retryState, trigger, itemId, options) => {
  logger.debugLog(`uploady.retry: about to retry item: ${itemId}`);
  return uploadFailedIds(uploader, retryState, trigger, [itemId], options);
};

const retry = (uploader, retryState, trigger, itemId, options) => {
  let result;

  if (itemId) {
    result = retryItem(uploader, retryState, trigger, itemId, options);
  } else {
    logger.debugLog(`uploady.retry: about to retry all failed item`);
    result = uploadFailedIds(uploader, retryState, trigger, null, options);
  }

  return result;
};

const retryBatch = (uploader, retryState, trigger, batchId, options) => {
  var _retryState$getState$;

  logger.debugLog(`uploady.retry: about to retry batch: ${batchId}`); //we make a copy of the ids because later on we use splice on this array

  const batchItemIds = (_retryState$getState$ = retryState.getState().batchIdsMap[batchId]) === null || _retryState$getState$ === void 0 ? void 0 : _retryState$getState$.slice();
  return batchItemIds ? uploadFailedIds(uploader, retryState, trigger, batchItemIds, options) : false;
};

const createRetryState = () => {
  const {
    state,
    update
  } = createState({
    batchIdsMap: {},
    failed: {}
  });
  return {
    updateState: updater => {
      update(updater);
    },
    getState: () => state
  };
};

const registerEvents = (uploader, retryState) => {
  uploader.on(UPLOADER_EVENTS.ITEM_FINALIZE, item => {
    if (~FAILED_STATES.indexOf(item.state)) {
      retryState.updateState(state => {
        state.failed[item.id] = item;
        const biMap = state.batchIdsMap[item.batchId] = state.batchIdsMap[item.batchId] || [];
        biMap.push(item.id);
      });
    }
  });
};
/**
 * an uploader enhancer function to add retry functionality
 */


const retryEnhancer = (uploader, trigger) => {
  const retryState = createRetryState();
  registerEvents(uploader, retryState);
  uploader.registerExtension(RETRY_EXT, {
    retry: (itemId, options) => retry(uploader, retryState, trigger, itemId, options),
    retryBatch: (batchId, options) => retryBatch(uploader, retryState, trigger, batchId, options)
  });
  return uploader;
};

export default retryEnhancer;